@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap);
.nanumpenscript * {
  font-family: "Nanum Pen Script", cursive;
}
@font-face {
  font-family: "Nanum Pen Script";
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/nanum-pen-script-v15-latin_korean-regular.92e4d711.eot)
      format("embedded-opentype"),
    url(/static/media/nanum-pen-script-v15-latin_korean-regular.de8cc00c.svg)
      format("svg"),
    url(/static/media/nanum-pen-script-v15-latin_korean-regular.e4c113f4.ttf)
      format("truetype"),
    url(/static/media/nanum-pen-script-v15-latin_korean-regular.c12fb8ef.woff)
      format("woff"),
    url(/static/media/nanum-pen-script-v15-latin_korean-regular.dd4381e9.woff2)
      format("woff2");
}

/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/NotoSansKR-Thin.4776c64f.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Thin.d11a10e3.woff) format("woff"),
    url(/static/media/NotoSansKR-Thin.0225d346.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/NotoSansKR-Light.ea4db2bb.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Light.1f7c8be8.woff) format("woff"),
    url(/static/media/NotoSansKR-Light.69a7091d.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/NotoSansKR-Regular.9139661f.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Regular.7c3141b3.woff) format("woff"),
    url(/static/media/NotoSansKR-Regular.432678b1.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/NotoSansKR-Medium.2de688e2.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Medium.e63313c2.woff) format("woff"),
    url(/static/media/NotoSansKR-Medium.f76d323a.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/NotoSansKR-Bold.0ae2f9c5.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Bold.11ec0139.woff) format("woff"),
    url(/static/media/NotoSansKR-Bold.42dd0b20.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/NotoSansKR-Black.32188d45.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Black.ad135e74.woff) format("woff"),
    url(/static/media/NotoSansKR-Black.931baa9e.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/NotoSansKR-Black.32188d45.woff2) format("woff2"),
    url(/static/media/NotoSansKR-Black.ad135e74.woff) format("woff"),
    url(/static/media/NotoSansKR-Black.931baa9e.otf) format("opentype");
}

html,
body {
  /* background-color: #000; */
  font-family: "Noto Sans KR";
}
.container {
  margin: 120px auto 0 auto;
  max-width: 1300px;
  /* background-color: #000;   */
  /* rgb(245, 245, 245); */
}
@media screen and (max-width: 600px) {
  .container {
    margin: 80px auto 0 auto;
  }
}
/* dark */
/* .container-dark {
  margin: 120px auto 0 auto;
  max-width: 1300px;
  background-color: rgb(24, 27, 44); 
  color: white;
}
@media screen and (max-width: 600px) {
  .container-dark {
    margin: 80px auto 0 auto;
    max-width: 1300px;
    min-height: 1000px;
  }
} */
/* .nav-container {
  margin: auto;
  background-color: #ffffff rgb(245, 245, 245);
} */

.footer-container {
  position: absolute;
  right: 0;
  left: 0;
  background-color: #4d4d4d;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.28px;
  text-align: left;
  color: #8e8e8e;
  padding-top: 42px;
  padding-bottom: 2rem;
  padding-left: 2rem;
  margin-top: 30px;
}

.homefooter-container {
  position: absolute;
  right: 0;
  left: 0;
  top: 900px; /* 홈에서 계산된 위치 */
  background-color: #4d4d4d;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.28px;
  text-align: left;
  color: #8e8e8e;
  padding-top: 42px;
  padding-bottom: 2rem;
  padding-left: 2rem;
  margin-top: 30px;
}
a {
  text-decoration: none;
}
@keyframes logoAnimation {
  5% {
    transform: rotate(-5deg) translate(0px, -8px);
  }
  10% {
    transform: rotate(0deg) translate(0px, -4px);
  }
  15% {
    transform: rotate(5deg) translate(0px, 0px);
  }
  20% {
    transform: rotate(0deg);
  }
}
@keyframes titleAnimation {
  5% {
    transform: rotate(0) translate(0px, -15px);
  }
  10% {
    transform: rotate(0deg) translate(0px, 5px);
  }
  15% {
    transform: rotate(0) translate(0px, 0px);
  }
  20% {
    transform: rotate(0);
  }
}
#logo {
  animation: logoAnimation 2s ease 0s infinite normal forwards;
}

#answer {
  animation: titleAnimation 2s ease 0s infinite normal forwards;
}
.marker {
  background-color: yellow;
}
blockquote {
  border-left: 5px solid #ccc;
  padding: 15px 0px 15px 15px
}
/* Full 캘린더 CSS 조절한 것 */
.fc .fc-button-primary {
  background-color: #606168;
  border-color: transparent;
}
.fc .fc-bg-event {
  background-color: transparent;
  opacity: 1;
  border-color: #f15624;
  color: #ffffff;
}

.fc-day-fast {
  background-color: #000105 !important;
}
.fc-day-today {
  background-color: #e8dfdc !important;
}
.fc table {
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 12px;
  cursor: pointer;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 0;
}
.fc .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc th {
  background-color: #ebebeb;
  height: 47px;
  vertical-align: middle;
}
.fc .fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.fc .fc-toolbar {
  background-color: #606168;
  flex: inherit;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  height: 46px;
}
.fc .fc-toolbar-title {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.32px;
  margin-left: 20px;
}
.fc-button-group:hover {
  background-color: #606168;
}
.fc-today-button .fc-button .fc-button-primary {
  display: none;
}
.fc-button {
  cursor: pointer;
}


/* Full 캘린더 CSS 조절한 것 (다크모드 - 가맹원view)*/
.fc .fc-button-primary {
  background-color: #606168;
  border-color: transparent;
}
.fc .fc-bg-event {
  background-color: transparent;
  opacity: 1;
  border-color: #f15624;
  color: #181717;
}
.fc-daygrid {
  color: #000 !important;
}

.fc-day-fast {
  background-color: #000105 !important;
}
.fc-day-today {
  background-color: #e8dfdc !important;
}
.fc table {
  border-color: #2e2b2b;
  background-color: #f8f8f8;
  font-size: 12px;
  cursor: pointer;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 0;
}
.fc .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc th {
  background-color: #ebebeb;
  height: 47px;
  vertical-align: middle;
}

.fc .fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.fc .fc-toolbar {
  background-color: #606168;
  flex: inherit;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  height: 46px;
}
.fc .fc-toolbar-title {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.32px;
  margin-left: 20px;
}
.fc-button-group:hover {
  background-color: #606168;
}
.fc-today-button .fc-button .fc-button-primary {
  display: none;
}
.fc-button {
  cursor: pointer;
}


